import React, { useEffect, createContext, useContext, useState } from "react";
import fetch from "isomorphic-fetch";
import Client from "shopify-buy";

// Context
import { CurrentLanguage } from "./current-language";

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `rinck_shopify_checkout_id`;

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [currentLanguage] = useContext(CurrentLanguage);
  const [client, setClient] = useState(null);
  const [checkout, setCheckout] = useState({ lineItems: [] });
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);

  // Initialize Shopify client
  useEffect(() => {
    const newClient = Client.buildClient(
      {
        domain: process.env.GATSBY_SHOPIFY_STORE_URL,
        storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
        language: currentLanguage === `en` ? `en` : `fr-FR`,
      },
      fetch
    );

    setClient(newClient);
  }, [currentLanguage]);

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  };

  // useEffect(() => {
  //   if (!client) return; // Ensure client is available

  //   const initializeCheckout = async () => {
  //     const existingCheckoutID = isBrowser
  //       ? localStorage.getItem(localStorageKey)
  //       : null;

  //     if (existingCheckoutID && existingCheckoutID !== `null`) {
  //       try {
  //         const existingCheckout = await client.checkout.fetch(
  //           existingCheckoutID
  //         );
  //         if (!existingCheckout.completedAt) {
  //           setCheckoutItem(existingCheckout);
  //           return;
  //         }
  //       } catch (e) {
  //         localStorage.setItem(localStorageKey, null);
  //       }
  //     }

  //     const newCheckout = await client.checkout.create();
  //     setCheckoutItem(newCheckout);
  //   };

  //   initializeCheckout();
  // }, [client, currentLanguage]);

  useEffect(() => {
    let isMounted = true; // to handle component unmount

    const initializeCheckout = async () => {
      if (!client || !isMounted) return;

      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      try {
        const existingCheckout = existingCheckoutID
          ? await client.checkout.fetch(existingCheckoutID)
          : null;
        const checkoutLanguage = existingCheckout?.customAttributes?.find(
          (attr) => attr.key === "language"
        )?.value;

        if (existingCheckout && !existingCheckout.completedAt) {
          if (checkoutLanguage !== currentLanguage) {
            // Create a new checkout and transfer line items
            const newCheckout = await client.checkout.create({
              customAttributes: [{ key: "language", value: currentLanguage }],
            });
            if (existingCheckout.lineItems.length > 0) {
              const lineItemsToAdd = existingCheckout.lineItems.map((item) => ({
                variantId: item.variant.id,
                quantity: item.quantity,
              }));
              const updatedCheckout = await client.checkout.addLineItems(
                newCheckout.id,
                lineItemsToAdd
              );
              if (isMounted) setCheckoutItem(updatedCheckout);
            } else {
              if (isMounted) setCheckoutItem(newCheckout);
            }
          } else {
            if (isMounted) setCheckoutItem(existingCheckout);
          }
        } else {
          // Create a fresh checkout
          const newCheckout = await client.checkout.create({
            customAttributes: [{ key: "language", value: currentLanguage }],
          });
          if (isMounted) setCheckoutItem(newCheckout);
        }
      } catch (e) {
        localStorage.setItem(localStorageKey, null);
      }
    };

    initializeCheckout();

    return () => {
      isMounted = false; // cleanup function to prevent state update on unmounted component
    };
  }, [client, currentLanguage]);

  // Other functions like addVariantToCart, removeLineItem, updateLineItem...
  const addVariantToCart = (variantId, quantity) => {
    setLoading(true);

    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
      });
  };

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true);

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const setValue = (value) => {
    if (isBrowser) {
      localStorage.setItem("rinck_customerAccessToken", JSON.stringify(value));
    }
  };

  const getCustomerAccessToken = () => {
    if (isBrowser) {
      return JSON.parse(localStorage.getItem("rinck_customerAccessToken"));
    } else {
      return null;
    }
  };

  const defaultValues = {
    cart: [],
    loading,
    customerAccessToken: getCustomerAccessToken(),
    client,
    checkout,
    setValue,
    addVariantToCart,
    removeLineItem,
    updateLineItem,
  };

  return (
    <StoreContext.Provider value={defaultValues}>
      {children}
    </StoreContext.Provider>
  );
};
