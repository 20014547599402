import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/global/page-seo";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Context
import { CurrentLanguage } from "../components/context/current-language";
import { PageColor } from "../components/context/page-color";
import { HeaderColor } from "../components/context/header-color";
import { GatsbyImage } from "gatsby-plugin-image";

const Page = styled.div`
  margin: 79px 0 0 0;
  padding: 51px 50px 0 50px;

  background-color: #efefef;

  @media (max-width: 1445px) {
    padding: 0 40px;
  }

  @media (max-width: 999px) {
    padding: 0 30px;
  }

  @media (max-width: 800px) {
    padding: 0;
  }
`;

const Introduction = styled.section`
  margin: 0 0 185px 0;

  @media (max-width: 800px) {
    margin: 0 0 140px 0;
  }

  & .image-container {
    grid-column: 1 / 7;
    padding: 0 25px 0 0;

    & img:not([aria-hidden="true"]) {
      border: 39px solid #fff;
      box-sizing: border-box;

      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 999px) {
        border: 27px solid #fff;
      }
    }
  }

  & .text-container {
    grid-column: 7 / 13;

    margin: 39px 0 0 0;
    padding: 0 0 0 25px;

    max-width: 500px;

    & p {
      font-size: 18px;
      line-height: 30px;
    }
  }

  @media (max-width: 1445px) {
    & .text-container {
      grid-column: 7 / 13;

      & p {
        font-size: 14px;
        line-height: 24px;
      }
    }

    & .image-container {
      grid-column: 1 / 7;
    }
  }

  @media (max-width: 999px) {
    & .text-container {
      margin: 19px 0 0 0;
    }
  }

  @media (max-width: 800px) {
    & .text-container {
      grid-column: 1 / 7;
      order: 2;

      padding: 39px 25px 0 25px;
      margin: 0;
    }

    & .image-container {
      grid-column: 1 / 7;
      order: 1;
      padding: 0;
    }
  }
`;

const JobListingsContainer = styled.section`
  & ol {
    max-width: 1400px;
    margin: 0 0 0 auto;

    &.headings {
      display: grid;
      grid-template-columns: 1fr 200px 1fr;
      grid-column-gap: 20px;

      padding: 0 0 45px 0;

      @media (max-width: 999px) {
        grid-template-columns: 1fr 120px 450px;
      }

      @media (max-width: 800px) {
        display: none;
      }
    }

    & li {
      &.single-job-listing {
        border-top: 1px solid #09184f;
        padding: 20px 0;

        & .job-title {
          & a {
            font-size: 12px;
            line-height: 24px;
          }

          @media (max-width: 999px) {
            & h2 {
              font-size: 30px;
              line-height: 40px;
            }
          }

          @media (max-width: 800px) {
            & h2 {
              font-size: 40px;
              line-height: 50px;

              margin: 0 0 5px 0;
            }
          }
        }

        & .job-description {
          & ul {
            margin: 0 0 1em 20px;

            & li {
              list-style: disc;
            }
          }
        }

        & p {
          &:first-of-type {
            margin-top: 0;
          }
        }
      }

      &.grid {
        display: grid;
        grid-template-columns: 1fr 200px 1fr;
        grid-column-gap: 20px;

        @media (max-width: 999px) {
          grid-template-columns: 1fr 120px 450px;
        }

        @media (max-width: 800px) {
          display: block;
          grid-template-columns: unset;
          grid-column-gap: unset;
        }
      }
    }
  }

  @media (max-width: 800px) {
    padding: 0 25px;

    & .job-location {
      margin: 15px 0 0 0;
    }
  }
`;

const SingleJobListing = ({ job, index, currentLanguage, emailAddress }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li key={`single_job_listing_${index}`} className="single-job-listing grid">
      <div className="column job-title">
        <PrismicRichText field={job.job_title.richText} />

        <div className="call-to-action">
          <a
            href={`mailto:${emailAddress}?subject=${job.job_title.text}`}
            subject
            className="apply uppercase gotham-light"
          >
            Apply
          </a>
        </div>
      </div>

      <div className="column job-location">
        <PrismicRichText field={job.job_location.richText} />
      </div>
      <div className="column job-description">
        <PrismicRichText field={job.job_description.richText} />

        {job.see_more_description.text.length > 0 && (
          <>
            {!isOpen && (
              <button type="button" onClick={() => setIsOpen(true)}>
                {currentLanguage === `en` ? `See More` : `Voir Plus`}
              </button>
            )}

            {isOpen && (
              <PrismicRichText field={job.see_more_description.richText} />
            )}
          </>
        )}
      </div>
    </li>
  );
};

const JoinUs = ({ data }) => {
  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  useEffect(() => {
    setPageColor(`#efefef`);
    setHeaderColor(`#efefef`);
  }, []);

  const listings = data.prismicJoinUs.data.job_listing.map((job, index) => {
    return (
      <SingleJobListing
        job={job}
        index={index}
        key={`single_job_listing_${index}`}
        currentLanguage={currentLanguage}
        emailAddress={data.prismicJoinUs.data.apply_email_address}
      />
    );
  });

  return (
    <>
      <PageSeo
        title={data.prismicJoinUs.data.title.text}
        image={null}
        description={null}
        url={data.prismicJoinUs.url}
      />

      <Page className="">
        <Introduction className="grid-12">
          <div className="image-container">
            {data.prismicJoinUs.data.image.fluid !== null && (
              <AspectRatioImageContainer image={data.prismicJoinUs.data.image}>
                <GatsbyImage
                  image={data.prismicJoinUs.data.image.gatsbyImageData}
                  alt={
                    data.prismicJoinUs.data.image.alt !== null
                      ? data.prismicJoinUs.data.image.alt
                      : ``
                  }
                  fetchpriority={`high`}
                  loading={`eager`}
                />
              </AspectRatioImageContainer>
            )}
          </div>

          <div className="text-container">
            <div className="title">
              <PrismicRichText
                field={data.prismicJoinUs.data.section_title.richText}
              />
            </div>

            <div className="text">
              <PrismicRichText field={data.prismicJoinUs.data.text.richText} />
            </div>
          </div>
        </Introduction>

        <JobListingsContainer>
          <ol className="headings">
            <li className="tag uppercase">Title</li>
            <li className="tag uppercase">Location</li>
            <li className="tag uppercase">Description</li>
          </ol>
          <ol>{listings}</ol>
        </JobListingsContainer>
      </Page>
    </>
  );
};

export default withPrismicPreview(JoinUs);

export const query = graphql`
  query JoinUsQuery($id: String, $lang: String) {
    prismicJoinUs(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title {
          text
        }
        section_title {
          richText
        }
        text {
          richText
        }
        image {
          alt
          dimensions {
            height
            width
          }
          gatsbyImageData
          fluid {
            src
            srcSet
            aspectRatio
          }
        }
        apply_email_address
        job_listing {
          job_title {
            richText
            text
          }
          job_location {
            richText
          }
          job_description {
            richText
          }
          see_more_description {
            richText
            text
          }
        }
      }
    }
  }
`;
